<template>
  <b-container fluid>
      <b-row align-h="center" align-v="center" class="min-vh-100 bg-light">
          <b-col align-self="center" class="text-center p-5">
            <b-icon icon="outlet" font-scale="8" variant="lightblue" class="mb-2"></b-icon>
            <h1 class="mb-4 font-weight-bold">404 - Page Not Found</h1>
            <p>The page you entered doesn't exist. Pleast return to the Leadership Development Tool.</p>
          </b-col>
      </b-row>
  </b-container>
</template>

<script>
export default {
    name: 'PageNotFound'
}
</script>